<script>
    import adminProfileStructure from '@components/adminProfileStructure'

    /**
     * Profile component
     */
    export default {
        page: {
            title: 'Admin',
        },
        components: {adminProfileStructure },
        data() {
            return {
                user: this.$store ? this.$store.state.auth.currentUser : {} || {},

                userCounts: {
                    totalUsers: {
                        count: 0,
                        message: 'Εγγεγραμένοι πελάτες (με ή χωρίς συνδρομή)',
                        icon: 'icon-people',
                        color: 'primary',
                    },
                    activeUsers: {
                        count: 0,
                        message: 'Ενεργοί πελάτες (με συνδρομή)',
                        icon: 'fe-users',
                        color: 'success',
                    },
                    inactiveUsers: {
                        count: 0 ,
                        message: 'Ανενεργοί πελάτες (με συνδρομή)',
                        icon: 'fe-users',
                        color: 'danger'
                    },
                    totalAthletes: {
                        count: 0,
                        message: 'Πελάτες',
                        icon: 'icon-pie-chart',
                        color: 'warning'
                    },
                    foods: {
                        count: 0,
                        message: 'Τρόφιμα',
                        icon: 'icon-energy',
                        color: 'warning'
                    },
                    dietPlans: {
                        count: 0,
                        message: 'Διατροφικά πλάνα',
                        icon: 'icon-book-open',
                        color: 'warning'
                    }
                },
            }
        },
        created: function(){
            this.$store.subscribe((mutation, state) => {
                if(mutation.type === 'auth/SET_CURRENT_USER'){
                    this.user = this.$store.state.auth.currentUser;
                }
            });
            this.getAdminCounts();
        },
        methods: {

            getAdminCounts(){
                this.$axios.get(`/admin/dashboard/counts`).then(result => {
                  for(const [key,val] of Object.entries(result.data)){
                    this.userCounts[key].count = val;
                  }

                }).catch(e => {
                    console.error(e);
                });
            }
        }
    }
</script>
<template>
    <adminProfileStructure :user="user" :hide-main="true">
        <template v-slot:admin-structure-beforecard>
            <div class="row">
                <div v-for="count in userCounts" :key="count.message" class="col-md-12 col-xl-4">
                    <div class="widget-rounded-circle card-box" style="border-radius:12px;">
                        <div class="row">
                            <div class="col-6">
                                <div :class="`avatar-lg rounded-circle bg-soft-${count.color} border-${count.color} border`">
                                    <i :class="`${ count.icon } font-22 avatar-title text-${ count.color}`"></i>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-right">
                                    <h1 class="text-dark font-weight-bolder mt-1"><span> {{count.count}}</span></h1>
                                    <p class="text-muted mb-1 ">{{count.message}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- end row-->
                    </div>
                    <!-- end widget-rounded-circle-->
                </div>
            </div>

        </template>
    </adminProfileStructure>
        <!-- end row-->
</template>
